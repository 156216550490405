import { useQuery } from '@apollo/client'
import { toaster } from 'evergreen-ui'
import { useEffect, useState } from 'react'
import { GET_SITES_ERROR_MESSAGE } from '../components/SiteDetails/siteUtils'
import { GET_SITES_QUERY } from '../queries/getSites'
import { ALL_REALMS_ID } from '../utils/constants'
import { useGlobalStore } from './useGlobalStore'
import { navigate } from 'gatsby'

export function useHandleSitesList({ sitesSectionRef, routeRealm }) {
  const { realmId, setShowPrompt } = useGlobalStore()
  const [siteSetRaw, setSiteSetRaw] = useState([])
  const [sitesSet, setSitesSet] = useState([])
  const [selectedSite, setSelectedSite] = useState()
  const [showSiteDetails, setShowSiteDetails] = useState(false)
  const [filterValue, setFilterValue] = useState('')
  const [showCreateSiteDialog, setShowCreateSiteDialog] = useState(false)

  // Realm Id that is set depending on the realm from URL and the global store
  const realRealmId =
    routeRealm === false ? -1 : routeRealm ? routeRealm : realmId

  function handleCreateSiteCloseModal() {
    setShowPrompt(false)
    setShowCreateSiteDialog(false)
  }

  function handleCreateSiteOpenModal() {
    setShowPrompt(true)
    setShowCreateSiteDialog(true)
  }

  function handleSiteDetailsClose() {
    setShowSiteDetails(false)
  }

  const { loading, data: sites } = useQuery(GET_SITES_QUERY, {
    skip: !realRealmId && realRealmId !== ALL_REALMS_ID,
    variables: {
      siteWhere: {
        realm_id: realRealmId > 0 ? { _eq: realRealmId } : {},
        isBlocked: { _eq: false },
        name: !realRealmId ? { _nlike: '%E2E-Site%' } : {}
      }
    },
    onError: _ => toaster.danger(GET_SITES_ERROR_MESSAGE)
  })
  useEffect(() => {
    function onSiteQuerySuccess(result) {
      const sites = result?.Site || []
      setSitesSet(sites)
      setSiteSetRaw(sites)
    }
    onSiteQuerySuccess(sites)
  }, [sites])

  function handleOpenSideSheet(siteId) {
    setSelectedSite({ id: siteId })
    setShowSiteDetails(true)
    navigate(`/realm/${realRealmId}/site/${siteId}`)
    /*     if (sitesSectionRef) {
      sitesSectionRef.current.classList.add('hide')
    } */
  }

  function handleSiteFilter(query) {
    setFilterValue(query)
    if (query === '') {
      return setSitesSet(siteSetRaw)
    }

    setSitesSet(
      siteSetRaw.filter(site => {
        const name = site.name
        return name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
      })
    )
  }

  function onSiteCreation(site) {
    handleOpenSideSheet(site.id)
  }

  return {
    loading,
    sitesSet,
    showCreateSiteDialog,
    showSiteDetails,
    selectedSite,
    filterValue,
    handleCreateSiteCloseModal,
    handleCreateSiteOpenModal,
    handleSiteFilter,
    handleOpenSideSheet,
    handleSiteDetailsClose,
    onSiteCreation
  }
}
